.top-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* background-color: #00baf0;
  background: linear-gradient(to left, #f46b45, #eea849); */
  color: #fff;
  height: 40px;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
  overflow: hidden;
}

.menu-button-container {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#menu-toggle {
  display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: "";
  margin-top: -8px;
}

.menu-button::after {
  content: "";
  margin-top: 8px;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
  background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media only screen and (max-width: 768px) {
  .menu-button-container {
    display: flex;
    z-index: 3;
  }
  /*   .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .card-4 .card-body {
    padding: 30px 20px;
  } */
  .menu {
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menu-toggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  #menu-toggle:checked ~ .menu li {
    border: 1px solid #333;
    height: 2.5em;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.2em 0;
    width: 100%;
    color: white;
    background-color: #222;
    z-index: 3;
    font-size: 14px;
  }
  .nav-item-button button {
    padding: 3px !important;
    font-size: 14px !important;
    border-radius: 5px !important;
    margin-top: 3px;
  }
  .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
  .nav-item-custom {
    /*   padding: 1px; */
  }
  /* 
  .button-container {
    margin-left: 90px;
    margin-top: 0px;
    padding: 0px;
    background-color: transparent;
  }
  .paginationItem {
    height: 20px;
  }
  .paginationItem {
    border: none;
    padding: 1px 5px;
    border-radius: 10%;
    height: 45px;
    position: relative;
    margin: 0 4px;
  }
  .prev,
  .next {
    display: none;
  }
  body {
    font-size: 14px !important;
  }
  .form-control {
    font-size: 14px;
  }
  .btn {
    line-height: 25px;
    padding: 2 0px;
    transition: all 0.4s ease;
    cursor: pointer;
    font-size: 14px;
  } */
  /* .datepickercss {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2px;
    margin-top: 0px;
    min-width: 200px;
    border: 0.5px solid rgb(177, 174, 174);
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
  } */
  /* .log-pagination {
    margin-top: 5px;
  }
  .log-pagination tr {
    border-radius: 5px;
  }
  .log-pagination,
  .balance-content,
  .request-content,
  .note-content,
  .commission-content {
    margin: 0px 0px 0px 0px;
    margin-top: 10px !important;
  }
  .input--style-4 {
    line-height: 28px;
    background: #ffffff;
    border-radius: 4px;
    padding: 0 1px;
    font-size: 14px;
    color: rgb(0, 0, 0);
    border: 1px solid #ced4da;
  }
  .input-group {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
  } */
  .label {
    font-size: 14px !important;
    max-height: 10px;
    display: flex;
  }
  .label {
    margin-bottom: 10px;
  }
  h2 {
    font-size: 16px;
  }
  h4 {
    font-size: 11px;
  }
  .sub-title {
    font-size: 12px;
    color: #0800ff;
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 5px;
  }
  /*  .flexButtons Button {
    margin-left: 10px !important;
  }
  .flexButtons {
    margin-top: 5px;
  }
  .text-area {
    min-width: 200px !important;
  }
  .userBody table {
    max-width: 300px !important;
  }
  .addUserBody,
  .editUserBody .row-space {
    gap: 100px !important;
    margin-top: 5px;
  }
  .addUserBody .card {
    margin: 10px !important;
  }
  .addUserBody,
  .editUserBody .selectpicker {
    margin-top: 5px;
    max-width: 135px;
  }
  .balanceBody .card-header {
    font-size: 15px !important;
  }
  .balanceBody {
    max-width: 340px !important;
    min-width: 0;
  } */
  .active-nav-item {
    display: flex;
    color: yellow;
  }
  /*   .modal-footer,
  .modal-header,
  .modal-body,
  .modal-content {
    max-width: 330px !important;
  } */
  /*  .user-row {
    max-width: 300px !important;
  }
  .left-panel,
  .right-panel {
    max-width: 300px !important;
    padding: 0px;
  }
  .Administrator,
  .User {
    display: flex;
    gap: 5px !important;
    padding: 5px !important;
    min-width: 100px;
    background-color: #87acbe;
    flex: 0.2;
    border-radius: 5px;
    flex-direction: column;
  }
  .action-buttons {
    display: flex;
    flex-direction: row !important;
  }
  .action-buttons Button {
    min-width: 100px !important;
  }
  .panel-container {
    margin: 0px !important;
    max-width: 300px !important;
    min-width: 0;
    margin: 0px !important;
    flex-direction: column;
  }
  .balanceRequestBody table {
    max-width: 300px !important;
  }
  .transactions {
    padding: 0px !important;
  } */
  .responsiveTable tbody tr {
    border: 1px solid rgb(255, 255, 255);
    padding: 0.25em;
    margin: 10px;
    max-width: 380px !important;
  }
  .responsiveTable tbody {
    margin-top: 5px;
  }
  .table-responsive-sm {
    display: block;
    width: 100%;
    background-color: white;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  /*  .header {
    max-height: 60px !important;
    max-width: 290px !important;
  }

  .add-note-container {
    display: flex;
    flex-direction: column;
    gap: 2px;
    background-color: lightblue;
    padding: 2px !important;
    border-radius: 2px;
  }
  .add-note-container input {
    min-width: 355px !important;
  }
  .add-note-container Button {
    max-width: 100px;
  }
  .noteBody {
    min-width: 360px !important;
    padding: 0px !important;
  }
  .box {
    inline-size: 350px !important;
  }
  .notes.alert {
    padding: 2px !important;
  }
  .login-label h1 {
    margin-left: 80px !important;
    font-size: 18px;
  }
  .card-container.card {
    margin-top: 10px;
    max-width: 350px !important;
    padding: 100px;
    background-color: white;
    border-radius: 10px;
  }
  .filter-selects {
    display: flex !important;
    gap: 5px;
  } */
}
