.rate-form .input--style-4 {
  max-height: 40px !important;
}
.rate-item select {
  max-height: 40px !important;
}
.rateBody {
  display: flex;
  flex-direction: column;
}
