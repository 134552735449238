/* Notes*/
.notes {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
}
.badge {
  background-color: green;
  color: white;
  text-align: left;
}
.alert-info {
  color: #080500;
  background-color: #ededed;
  border-color: #ffffff;
}
.note-date {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.notes.alert {
  margin-bottom: 2px !important;
}
.add-note-container textarea {
  min-width: 800px;
}
.add-note-container button {
  max-height: 40px;
}
.box {
  word-break: break-all;
  inline-size: 1470px;
}
.add-note-container {
  display: flex;
  gap: 5px;
  background-color: lightblue;
  padding: 10px;
  border-radius: 5px;
}
.add-note-container input {
  min-width: 60% !important;
}
.error-message {
  margin: 0px 300px 0px 300px;
}
