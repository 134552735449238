@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext");
.transactions,
.balanceRequestBody,
.noteBody {
  display: flex;
  flex-direction: column;
}

.active-nav-item {
  display: none;
}

.search-row {
  display: flex;
  justify-content: center;
  padding: 10px 5% 10px 5%;
  /*  margin-top: 0px; */
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 0.5px 0.5px rgb(0 0 0 / 30%);
  gap: 10px;
  /* margin-bottom: 5px; */
  margin: auto;
}
.search-row select {
  border-radius: 5px;
  min-width: 100px;
  margin-top: 5px;
}
.search-row-add {
  display: flex;
  justify-content: left;
  padding: 10px 400px 10px 400px;
  margin-top: 0px;
  background-color: #f5f5f5;
  box-shadow: 0 0.5px 0.5px rgb(0 0 0 / 30%);
  gap: 10px;
  margin-bottom: 5px;
}
input:disabled {
  background-color: #e9ecef;
}

table {
  border-radius: 50px;
  border-collapse: collapse;
  border-spacing: 0;
}
table,
th,
td {
  border: 1px solid black;
}
th,
td {
  padding: 3px;
  vertical-align: top;
}
th {
  text-align: left;
}

tr:nth-child(even) {
  background-color: #eae5e5;
}

.title {
  margin-left: 50px;
  margin-top: 10px;
}

form {
  display: block;
  margin-top: 0em;
}
.card-container.card {
  margin-top: 100px;
  max-width: 450px !important;
  padding: 100px;
  background-color: white;
  border-radius: 10px;
}
.card {
  padding: 20px 25px 30px;
  margin: 10px auto 10px;
  border-radius: 2px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 30%);
}
/* Navigation */
.navigation {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: "Source Sans Pro";
}

/* Header */
header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 1px 8px #ddd;
  background-color: #282c34;
  min-height: 60px;
}

header li {
  list-style: none;
  display: inline-block;
}

/* Edit Page */
.Page,
.section {
  display: flex;
  flex-direction: column;
}
.section-item {
  display: flex;
  flex-direction: column;
  max-width: 10%;
  margin-left: 30px;
}
.list-buttons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
.list-buttons Button {
  margin-left: 30px;
}

.usersTable {
  margin-top: 20px;
  margin-left: 5px;
  width: 100%;
}
.usersTable tr {
  border-bottom: 0.5px solid rgb(162, 157, 157);
}
.commission-calculator {
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  padding: 5px;
  border-radius: 5px;
}
.request-item {
  display: flex;
  gap: 5px;
}
.rate-item {
  display: flex;
  gap: 5px;
}
.request-form {
  display: flex;
  gap: 50px;
  /*  margin-left: 170px;
  margin-right: 220px; */
  background-color: lightgray;
  padding: 15px;
}
.rate-form {
  display: flex;
  gap: 50px;
  /*  margin-left: 170px;
  margin-right: 220px; */
  background-color: lightgray;
  padding: 15px;
}

.well {
  background-color: #dcdcdc;
}
fieldset {
  max-width: 1200px;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row-space {
  display: flex;
  gap: 250px;
}

.col-2 {
  width: -webkit-calc((100% - 30px) / 2);
  width: -moz-calc((100% - 30px) / 2);
  width: calc((100% - 30px) / 2);
}

li > ol,
li > ul {
  margin-bottom: 0;
}

/**
   * 1. Reset Chrome and Firefox behaviour which sets a `min-width: min-content;`
   *    on fieldsets.
   */
fieldset {
  min-width: 0;
  /* [1] */
  border: 0;
}

button {
  outline: none;
  background: none;
  border: none;
}

/* ==========================================================================
     #PAGE WRAPPER
     ========================================================================== */
.page-wrapper {
  min-height: 100vh;
}

body {
  font-weight: 400;
  font-family: "Source Sans Pro";
  font-size: 18px;
  /* background-color: rgb(221, 221, 221); */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 15px;
}

h6 {
  font-size: 13px;
}

/* ==========================================================================
     #BACKGROUND
     ========================================================================== */
.bg-blue {
  background: #2c6ed5;
}

.bg-red {
  background: #fa4251;
}

/* ==========================================================================
     #SPACING
     ========================================================================== */
.p-t-100 {
  padding-top: 100px;
}

.p-t-130 {
  padding-top: 5px;
}

.p-t-180 {
  padding-top: 180px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-b-100 {
  padding-bottom: 100px;
}

.m-r-45 {
  margin-right: 45px;
}

/* ==========================================================================
     #WRAPPER
     ========================================================================== */
.wrapper {
  margin: 0 auto;
}

.wrapper--w960 {
  max-width: 960px;
}

.wrapper--w780 {
  max-width: 780px;
}

.wrapper--w680 {
  max-width: 680px;
}

td.active {
  background-color: #2c6ed5;
}

input[type="date" i] {
  padding: 14px;
}

/* ==========================================================================
     #FORM
     ========================================================================== */
input {
  outline: none;
  margin: 0;
  border: none;
  width: 200px;
  font-size: 14px;
  font-family: inherit;
  line-height: 35px;
  background: #fafafa;
  border-radius: 5px;
  padding: 0 10px;
  color: #666;
}

.input--style-4 {
  line-height: 35px;
  background: #ffffff;
  /* -webkit-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  box-shadow: inset 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px; */
  border-radius: 5px;
  padding: 0 10px;
  font-size: 16px;
  color: rgb(0, 0, 0);
  border: 1px solid #ced4da;
  /*  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease; */
}
.input--style-4:focus {
  border: 2px solid orange;
}

.label {
  font-size: 16px;
  color: #555;
  text-transform: capitalize;
  display: block;
  margin-bottom: 5px;
}

.input-group {
  position: relative;
  margin-bottom: 22px;
}

.input-group-icon {
  position: relative;
}

.input-icon {
  position: absolute;
  font-size: 16px;
  color: #999;
  right: 18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.card-4 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
}

hr {
  position: relative;
  top: 10px;
  border: none;
  height: 12px;
  background: rgb(55, 23, 233);
  margin-bottom: 20px;
}
/* transaction add page */

.form-control {
  width: fit-content;
}

.required:after {
  content: " *";
  color: red;
}
.nav-item {
  padding: 5px 10px;
  text-decoration: none;
}
.nav-item-custom {
  margin-top: 8px;
}
.nav-item-custom a {
  padding: 20px 10px;
  text-decoration: none;
  margin-top: 10px;
  transform: scale(1.1);
}
.nav-item-button button {
  padding: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  background-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0px 5px 0px 0px rgba(255, 0, 0, 0.25);
  border-radius: 10px;
  cursor: pointer;
  transition: 70ms;
}
.active a {
  color: rgb(234, 255, 0);
  text-decoration: none;
  transition: 0.5s;
}

a {
  color: rgb(255, 255, 255);
}
a:hover {
  color: rgb(249, 61, 19);
}
dl,
ol,
ul {
  margin-top: 12px;
  margin-bottom: 1rem;
}

.welcome-text-desktop {
  display: block;
  color: rgb(234, 160, 12);
  margin-left: 200px;
  margin-top: 15px;
}
.welcome-text-mobile {
  display: none;
}

/* Modal */
.modal-content {
  width: 140% !important;
  margin-top: 300px;
  margin-right: 150px;
  padding: 20px;
}
.btn {
  max-width: 100px;
}
.login-welcome-text {
  color: #0c8db7;
  margin-left: 40% !important;
  font-weight: bold;
  margin-top: 50px !important;
}
.ticket-number {
  color: blue;
}
.table td,
.table th {
  padding: 0.25rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.optimize-label {
  color: orange;

  cursor: pointer;
}
.enquiries-label {
  color: red;
  cursor: pointer;
}
.optimize-label:hover {
  color: rgb(230, 188, 111);
}
.enquiries-label:hover {
  color: lightcoral;
  cursor: pointer;
}
