/* ADD page */
.button-container {
  margin-top: 5px;
  padding-right: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  padding: 5px;
}
.button-container Button {
  margin-bottom: 5px;
  max-height: 40px;
  margin-left: 20px;
}
.search-row-filters {
  border: 1px solid black;
  padding: 5px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  gap: 5px;
}

.add-search-container .Card {
  max-width: 300px;
}
.add-search-container {
  margin: 0px 300px 0px 300px;
  margin-top: 20px;
  min-height: 100vh;
}

.receiverInfo {
  display: flex;
  gap: 20px;
}
.receiverInfo Button {
  max-height: 50px;
  margin-top: 00px;
}
.receiverInfo .card-text {
  min-width: 50%;
}
.card-text {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.add-search-input {
  min-width: 60%;
  min-height: 30px;
}
.tx-search-input {
  min-width: 10%;
  margin-top: 10px;
  max-height: 45px;
}
.rate-calculator-input {
  min-width: 150px;
  min-height: 35px;
  max-height: 45px;
  border: 1px solid darkblue !important;
  font-size: 20px !important;
  font-weight: bold;
}

.rate-calculator-input:disabled {
  background-color: #e9ecef !important;
}

.rate-calculator-item select {
  min-height: 45px;
  background: darkblue;
  color: white;
  /* font-weight: bold; */
}
.rate-calculator-item {
  display: flex;
  gap: 0px;
}
.rate-calculator-container {
  display: flex;
  gap: 20px;
  margin: auto;
  min-width: 1000px;
}
.rate-calculator-container img {
  max-width: 20px;
}

/* Pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 100%;
}
.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}
.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}
.paginationItem.active {
  color: rgb(37, 24, 218);
  /* color: #000; */
  pointer-events: none;
  /*  background-color: rgb(247, 183, 10); */
  /* background: #fff; */
}
.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: rgb(48, 45, 45);
}
.pagination-row {
  grid-area: pagination;
}
.search-sender-details,
.receiver-details {
  border: 0.5px solid rgb(189, 187, 187);
  padding: 10px;
  margin-top: 5px;
  border-radius: 5px;
}
.search-sender-details {
  display: flex;
  gap: 50px;
  background-color: lightblue;
}
.reciever-details .card {
  padding: 0px !important;
  border-radius: 10px !important;
}
.reciever-details {
  max-width: 800px;
  margin-left: 300px;
}
.send-agent {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.add-back-button {
  min-width: 100px;
}
.add-add-button {
  margin-left: 40px !important;
  min-width: 100px;
}
.m-r-100 {
  margin-right: 100px;
}
.card-body-details {
  padding: 5px 0px 0px 0px;
  width: 100%;
  background-color: #0070c4;
  margin-bottom: 0px;
}
.w-400 {
  min-width: 400% !important;
}
.send-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  margin-right: 5px;
  cursor: pointer;
}
.modal-duplicate-info {
  display: flex;
  gap: 10px;
}
/* EDIT */
.edit-top {
  font-size: 24px;
  color: #525252;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}
.sub-title {
  font-size: 16px;
  color: black;
  font-weight: bold;
  margin-bottom: 15px;
}
.addTransaction .row-space {
  gap: 80px;
}
.addTransaction {
  font-family: "Source Sans Pro" !important;
  background-color: rgb(221, 221, 221);
}
.addTransaction .wrapper--w680 {
  max-width: 1100px;
}
.w-200 {
  min-width: 200px;
}
.w-500 {
  min-width: 500px;
}
.w-290 {
  min-width: 290px;
}
.edit-update-button {
  margin-left: 325px;
  min-width: 100px;
}
.edit-back-button {
  margin-left: 50px;
  min-width: 100px;
}

/* ********************************* */
.name,
.country {
  display: flex;
  gap: 10px;
}

.nameTag:hover {
  background-color: #a4b5a6;
  cursor: pointer;
  color: rgb(255, 255, 255);
}
.dataContainer {
  border-radius: 10px;
  box-shadow: 0px 8px 20px 0px rgb(0 0 0 / 20%);
}
.datepickercss {
  margin-left: 10px;
  display: flex;
  gap: 10px;
  margin-top: 0px;
  min-width: 400px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
}
.datepickercss input {
  max-width: 110px;
}
.react-datepicker-wrapper {
  width: 10% !important;
}
.notifier {
  max-width: 600px;
  position: fixed;
  margin-top: 10px;
}
.footer-info {
  display: flex;
  gap: 150px;
  font-weight: normal;
  /* margin-left: 30px; */
  font-size: 20px;
  margin-top: 10px;
}
.footer-item {
  display: flex;
  flex-direction: column;
}
.transactions hr {
  position: relative;
  top: 10px;
  border: none;
  height: 2px;
  background: rgb(75, 75, 76);
  margin-bottom: 20px;
}
.transactions {
  margin-top: 5px;
  display: flex;
  gap: 10px;
}
.noTxText {
  margin-left: 40%;
  color: darkblue;
  margin-top: 5%;
  font-size: x-large;
}
.noTxText-10 {
  margin-left: 10%;
  color: darkblue;
  margin-top: 5%;
  font-size: x-large;
}
/* status color */
.statusColor-red {
  color: red;
}
.statusColor-green {
  color: green;
}
.statusColor-green-spaid {
  background-color: green;
  color: white;
}
.statusColor-green-unpaid {
  background-color: rgb(128, 0, 45);
  color: white;
}
.statusColor-yellow {
  color: rgb(213 103 8);
}
.statusColor-black {
  color: black;
}

/* header colors and background */
.header-color-id {
  color: blue;
  background-color: #ebe7e0;
}
.header-color-sender {
  background-color: #a9dfbf;
}
.header-color-receiver {
  background-color: #85c1e9;
}
.header-color-other-info {
  background-color: #f9e79f;
}
/* not paid icon */
.not-paid-icon {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  margin-right: 5px;
}
/* Date */
.date-item {
  max-width: 300px;
  display: flex;
  gap: 5px;
}
.m-t-5 {
  margin-top: 5px;
}
/* apply button */
.apply-button {
  max-height: 40px;
  margin: 10px 10px 10px 10px;
}
.filter-selects {
  display: flex !important;
}
.filter-inputs {
  display: flex !important;
}
.filter-buttons {
  display: flex !important;
  margin: auto;
}
.footer-item-value-paid {
  color: #28a745;
}
.footer-item-value-cancelled {
  color: rgb(255 0 0);
}
.footer-item-value-unpaid {
  color: rgb(213 103 8);
}
.balanceInformation {
  display: flex;
  gap: 10px;
  padding: 5px;
  border-radius: 5px;
  background-color: lightgray;
}
.add-row {
  max-width: 40% !important;
}
.tx-enhancements {
  display: flex;
  flex-direction: column;
}
.add-flex-buttons {
  display: flex;
  gap: 50px;
}
