@media screen and (max-width: 600px) {
  .balanceBody .cards {
    flex-direction: column;
  }
  .paginationItem {
    background: #fff;
    border: 1px solid #666 !important;
    padding: 2px 5px !important;
    border-radius: 10% !important;
    height: 30px !important;
    width: 30px !important;
  }
  .footer-info {
    display: flex;
    gap: 40px !important;
    font-weight: normal;
    margin-left: 10px !important;
    font-size: 14px !important;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .footer-item {
    display: flex;
    flex-direction: column;
  }
  .noTxText {
    margin-left: 20% !important;
    color: darkblue;
    margin-top: 5%;
    font-size: small !important;
  }
  .search-row {
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
    flex-direction: column;
    margin: auto;
    max-width: 100% !important;
    padding: 5px;
    font-size: 14px !important;
  }
  /* ADD */
  .table td,
  .table th {
    padding: 0px !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  .receiver-details {
    border: 0.5px solid rgb(189, 187, 187);
    padding: 8px !important;
    margin-top: 2px !important;
    border-radius: 5px;
    min-width: 300px;
  }
  .sender-details {
    border: 0.5px solid rgb(189, 187, 187);
    padding: 8px !important;
    margin-top: 2px !important;
    border-radius: 5px;
    min-width: 300px;
  }
  .rate-calculator-input {
    min-width: 50px !important;
    min-height: 15px !important;
    max-height: 45px;
    border: 1px solid darkblue !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .rate-calculator-item select {
    min-height: 15px !important;
    background: darkblue;
    color: white;
    font-size: 14px !important;
    /* font-weight: bold; */
  }
  .rate-calculator-container img {
    display: none;
  }
  .modal-content {
    width: 100% !important;
    margin-top: 250px !important;
    margin-right: 50px !important;
    padding: 5px !important;
    max-width: 350px !important;
  }
  .rate-calculator-container .input--style-4 {
    min-width: 100px !important;
  }
  .modal-header {
    font-size: 14px !important;
  }
  .rate-calculator-container {
    display: flex;
    gap: 5px;
    margin: auto;
    min-width: 100px !important;
    flex-direction: column;
    font-size: 14px !important;
  }
  .apply-button {
    margin-left: 5px;
  }
  .reciever-details {
    max-width: 500px;
    margin-left: 5px !important;
    font-size: 16px !important;
  }
  .card-reciever-item {
    display: flex;
    flex-direction: column;
  }
  .search-sender-details {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .name,
  .country {
    display: flex;
    gap: 5px !important;
  }
  .m-r-100 {
    margin-right: 5px !important;
  }
  .card-text {
    display: flex;
    flex-direction: row;
    gap: 15px !important;
    justify-content: space-between;
  }
  .card-text div:last-child {
    margin-left: auto;
  }
  .search-sender-details {
    display: flex;
    gap: 5px !important;
    background-color: lightblue;
  }
  .input--style-4 {
    line-height: 30px !important;
    background: #ffffff;
    border-radius: 5px;
    padding: 0 2px !important;
    font-size: 14px !important;
    color: rgb(0, 0, 0);
    border: 1px solid #ced4da;
    min-width: 280px !important;
  }
  .add-search-container {
    margin: auto !important;
    margin-top: 20px;
    min-height: 100vh;
  }
  .addTransaction .search-row {
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
    flex-direction: row !important;
    margin: auto;
    max-width: 100% !important;
    padding: 5px;
    font-size: 14px !important;
  }
  .noTxText-10 {
    margin-left: 10%;
    color: darkblue;
    margin-top: 5%;
    font-size: 14px !important;
  }
  .add-search-input {
    min-width: 60% !important;
    min-height: 35px !important;
    margin-left: 5px !important;
  }
  .add-back-button {
    margin-left: 0px !important;
    min-width: 10px !important;
  }
  .search-row select {
    min-height: 20px !important;
  }
  ul {
    display: flex;
  }
  .nav-link {
    display: block;
    padding: 1.2rem 1.5rem !important;
    color: rgb(255, 255, 255) !important;
  }
  .addTransaction .row-space {
    gap: 1px !important;
    display: flex;
    flex-direction: column;
    column-gap: 0;
  }
  .flexButtons {
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
  .welcome-text-mobile {
    display: block;
    color: rgb(234, 160, 12);
    margin-left: 50px;
    margin-right: 20px;
    margin-top: 0px;
  }
  .welcome-text-desktop {
    display: none;
  }
  .datepickercss {
    margin: auto;
    display: flex;
    gap: 3px !important;
    margin-top: 0px;
    min-width: 300px;
    padding: 5px !important ;
    border-radius: 5px;
    font-size: 14px !important;
    margin-left: 0px !important;
  }
  .datepickercss input {
    max-width: 110px;
  }
  .date-item {
    max-width: 100px;
    display: flex;
    gap: 4px !important;
  }
  .button-container {
    margin: auto !important;
  }
  .filter-selects {
    display: flex !important;
    gap: 20px !important;
  }
  .filter-inputs {
    display: flex !important;
    max-width: 350px !important;
    gap: 10px !important;
  }
  .filter-inputs .input--style-4 {
    max-height: 40px !important;
    min-width: 190px !important;
    max-width: 300px !important;
  }
  Button {
    size: "sm";
  }
  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .row-space {
    display: flex;
    gap: 5px !important;
  }

  .col-2 {
    width: -webkit-calc((100% - 30px) / 2);
    width: -moz-calc((100% - 30px) / 2);
    width: calc((100% - 30px) / 2);
  }
  .card-4 .card-body {
    padding: 3px 2px;
  }
  .add-add-button {
    margin-left: 40px !important;
    min-width: 100px;
  }
  /* EDIT */
  .edit-update-button {
    margin-left: 0px !important;
    min-width: 100px;
  }
  /* USER */
  .userBody {
    padding: 0px !important;
    margin-top: 20px;
    min-width: 350px !important;
    background-color: white;
    margin-bottom: 20px;
  }
  .addUserBody .row-space {
    gap: 1px !important;
    display: flex;
    flex-direction: column;
    column-gap: 0;
  }
  .editUserBody .row-space {
    gap: 1px !important;
    display: flex;
    flex-direction: column;
    column-gap: 0;
  }
  .update-user-button {
    margin-left: 0px !important;
    min-width: 100px;
  }
  .update-user-back-button {
    margin-left: 50px;
    min-width: 100px;
  }
  /* USER BALANCE */
  .panel-container {
    margin: 2px !important;
    display: flex;
    max-width: 350px !important;
    flex-direction: column !important;
    min-width: none !important;
    gap: 5px;
  }
  .right-panel {
    padding: 1px 1px 1px 1px !important;
    flex: 1 !important;
    margin-left: 1px !important;
    border-radius: 5px;
  }

  .Administrator,
  .User {
    display: flex;
    gap: 10px !important;
    padding: 5px !important;
    max-width: 400px;
    background-color: #87acbe;
    flex: 0.2;
    border-radius: 5px;
    flex-direction: column !important;
  }

  .balanceInfo {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 5px !important;
    gap: 0.8px;
    color: white;
  }

  .userLabel {
    flex: 0.3;
    margin-top: 5px !important;
    font-weight: bold;
  }
  .balance-details {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    margin-top: 5px !important;
  }
  .action-buttons {
    display: flex;
    flex-direction: row !important;
    gap: 20px;
    justify-content: center;
  }
  .left-panel {
    padding: 10px 0px 0px 10px;
    flex: 1 !important;
    max-width: 400px !important;
    /* border-top: 1px solid rgb(223, 220, 220);
  border-left: 1px solid rgb(223, 220, 220);
  border-bottom: 1px solid rgb(223, 220, 220); */
    border-radius: 10px;
  }
  .balanceBody {
    padding: 2px !important;
    margin-top: 2px !important;
    min-height: 90vh;
  }
  .user-row .active {
    background-color: #dea054 !important;
    color: white;
  }

  /* COMMISSION */
  .commision-form {
    display: flex;
    gap: 5px !important;
    flex-direction: column;
    background-color: lightgray;
    padding: 5px;
  }
  .commission-calculator .commision-form {
    gap: 5px !important;
    margin: auto;
  }
  .commission-result {
    margin-left: 5px;
    font-size: 14px !important;
    font-weight: 500;
    color: rgb(67, 58, 253);
    display: flex;
    flex-direction: column !important;
    gap: 2px !important;
  }
  .commission-add-button {
    min-width: 200px !important;
  }
  .commission-calculator .commision-form .commission-item {
    margin: none !important;
  }

  /* NOTES */

  .add-note-container {
    display: flex;
    gap: 5px;
    background-color: lightblue;
    padding: 5px !important;
    border-radius: 5px;
    flex-direction: column !important;
  }
  .add-note-container input {
    min-width: 90% !important;
  }
  .box {
    word-break: break-all;
    inline-size: 350px !important;
  }
  .notes.alert {
    margin-bottom: 2px !important;
    padding: 5px 5px !important;
  }
  .request-form .input--style-4 {
    max-height: 40px !important;
  }
  .request-form {
    display: flex;
    flex-direction: column !important;
    padding: 5px !important;
    gap: 10px !important;
  }

  .request-item select {
    max-height: 40px !important;
    max-width: none !important;
  }
  .request-add-button {
    min-width: 200px !important;
  }
  /* RATE */
  .rate-item select {
    max-height: 40px !important;
    max-width: none !important;
  }
  .rate-form .input--style-4 {
    max-height: 40px !important;
    min-width: 20px !important;
    max-width: 60px !important;
  }
  .rate-form {
    display: flex;
    grid-gap: 5px !important;
    gap: 5px !important;
    background-color: lightgray;
    padding: 5px !important;
  }
  .rateBody .search-row select {
    border-radius: 5px;
    min-width: none !important;
    margin-top: 0px !important;
  }
  .rate-content .input--style-4 {
    min-width: 20px !important;
    max-width: 100px !important;
  }
  /* BALANCE */
  .balance-modal .input--style-4 {
    min-width: 180px !important;
  }
  /* NOTES */
  .error-message {
    margin: auto !important;
  }
  /* Login */

  .login-welcome-text {
    color: #0c8db7;
    margin-left: 839px;
    font-weight: bold;
    margin-top: 100px;
    margin-left: 5% !important;
  }
  h3 {
    font-size: 20px !important;
  }
  td {
    font-size: small;
  }
  .search-row-filters {
    border: 1px solid black;
    padding: 5px;
    display: flex;
    flex-direction: column !important;
    border-radius: 5px;
    gap: 5px;
  }
  .label {
    margin-bottom: 10px;
    min-width: 100px !important;
  }
  .add-note-container textarea {
    max-width: 550px !important;
    min-width: 100px !important;
  }
}
