.profile-img-card {
  width: 56px;
  height: 56px;
  display: block;
  margin-right: 10px;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.cards {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  text-align: center;
  max-width: 1200px;
}

.card-header {
  font-weight: bold;
  text-align: center;
}

.card-actions {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 10px;
}
.card-actions Button {
  min-width: 50px;
}

.card-actions .card-header :hover {
  background-color: #ccc;
}
.balanceBody {
  width: max-content;
  height: max-content;
  margin: auto;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  min-height: 90vh;
  background-color: white;
  min-width: 1000px;
}
.panel-container {
  margin: 20px;
  display: flex;
  min-width: 900px;
}

.left-panel {
  padding: 10px 0px 0px 10px;
  flex: 0.5;
  /* border-top: 1px solid rgb(223, 220, 220);
  border-left: 1px solid rgb(223, 220, 220);
  border-bottom: 1px solid rgb(223, 220, 220); */
  border-radius: 10px;
}
.left-panel .user-row:hover {
  background-color: rgb(190, 186, 186);
  cursor: pointer;
  /*  border: 0.5px solid black;
  border-radius: 5px; */
}
.right-panel {
  padding: 10px 10px 0px 0px;
  flex: 1.5;
  /* border-top: 0.5px solid rgb(137, 133, 133);
  border-bottom: 0.5px solid rgb(137, 133, 133);
  border-right: 0.5px solid rgb(137, 133, 133); */
  margin-left: 10px;
  border-radius: 5px;
}
.username {
  margin-top: 22px;
  font-weight: bold;
}
.username-img {
  display: flex;
  box-shadow: 2px 4px 4px rgba(98, 96, 96, 0.3);
}
.balanceInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
  gap: 0.8px;
  color: white;
}
.Administrator,
.User {
  display: flex;
  gap: 50px;
  padding: 20px;
  min-width: 100px;
  background-color: #87acbe;
  flex: 0.2;
  border-radius: 5px;
}
.userLabel {
  flex: 0.3;
  margin-top: 30px;
  font-weight: bold;
}
.balance-details {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  margin-top: 20px;
}
.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.user-row .active {
  background-color: #87acbe;
  color: white;
}
.username {
  padding: 5px !important;
  margin-top: 0px !important;
}
