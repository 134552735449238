.users-list {
  padding: 10px;
  margin-top: 10px;
  -webkit-box-shadow: -2px -3px 14px 2px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: -2px -3px 14px 2px rgba(0, 0, 0, 0.61);
  box-shadow: -2px -3px 14px 2px rgba(0, 0, 0, 0.61);
}
.addUserBody select {
  max-width: 200px;
}
.addUserBody .col-2 {
  max-width: 18.666667% !important;
}
.userAddButton {
  padding-left: 20px;
  margin-top: 20px;
}
.userBody {
  width: max-content;
  height: max-content;
  margin: auto;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  min-width: 1000px;
  background-color: white;
  margin-bottom: 20px;
}
.update-user-button {
  margin-left: 180px;
  min-width: 100px;
}
.update-user-back-button {
  margin-left: 50px;
  min-width: 100px;
}
