.commission-calculator {
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  padding: 5px;
  border-radius: 5px;
}
.request-item,
.commission-item {
  display: flex;
  gap: 5px;
}
.request-form,
.commision-form {
  display: flex;
  gap: 50px;
  /*  margin-left: 170px;
  margin-right: 220px; */
  background-color: lightgray;
  padding: 15px;
}
.commission-calculator .commision-form {
  gap: 20px !important;
  margin: auto;
}
.commission-calculator .commision-form .commission-item {
  margin: auto;
}
.commission-result {
  margin-left: 50px;
  font-size: 20px;
  font-weight: 500;
  color: rgb(67, 58, 253);
  display: flex;
  gap: 30px;
}
.commission-result .final {
  color: green;
}
.commision-form .form-control {
  max-width: 110px;
}
.link-commission-calculator {
  color: blue;
}
.link-commission-calculator:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.commission-add-button {
  max-width: 200px !important;
}
